import { Heading, Box, Anchor, Text, Image, ResponsiveContext, Grid } from "grommet";
import ImageCenteredComponent from "./ImageCenteredComponent";
import { Phone, Mail, Language, Organization, Location } from "grommet-icons";
import ImageHeading from "./ImageHeading";
import { useContext } from "react";
 const color = "#011640";
const Contact = (props) => {
   
    const { image } = props;
    const size = useContext(ResponsiveContext);
    let flexDirection = 'row';
    let columns = '1/2';
    if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        flexDirection = 'column';

    }
    return (
        <Box
          //  pad="small"
            alignSelf="center"
            align="center"
            flexDirection={flexDirection}
        >
            <Box align="center"
                justify="center"
                pad="medium"
                background={
                    {
                        image: "url(./assets/images/reception_2.jpg)",
                      //  image: `url(${image.src})`,
                        size: "cover",
                        color: "neutral-1"
                    }
                }
                height="medium"
                width="100%">
                <Heading
                    className="title"
                >CONTACT US TO
                    <br />
                    FIND OUT MORE
                    <br />
                </Heading>
                <Heading className="title" level="3">We will be happy to assist</Heading>
            </Box>
            <Box>
                <Box pad="small">
                    <Heading className="title" alignSelf="start" level="2">Contact Us Directly For More Information</Heading>
                </Box>
                {
                    contactItems.map((item, index) => {
                        return (
                            <Box align="start" gap="medium" pad="small" key={index}>
                                {item}
                            </Box>
                        )

                    })
                }
            </Box>



        </Box>
    )
};

const contactItems = [
    <Box direction="row" gap="small">
        <Phone color={color} />
        <Anchor
            label="+254 799 27 27 27"
            color={"black"}
            weight="normal"
            href="tel:254799272727"
        />
        <Text>/</Text>
        <Anchor
            label="+254 799 42 76 67"
            color={"black"}
            weight="normal"
            href="tel:254799427667"
        />
    </Box>,
    <Anchor
        color="black"
        weight="normal"
        label="info@pendovillasdiani.com"
        href="mailto:info@pendovillasdiani.com"
        icon={<Mail color={color}/>}
    />,
    <Anchor
        color="black"
        weight="normal"
        label="www.pendovillasdiani.com"
        href="https://pendovillasdiani.com/"
        icon={<Language color={color} />}
    />,
    <Box direction="row" gap="small">
        <Location color={color}/>
        <Text>Next To Ukunda Air Strip, Off, Diani Beach</Text>
    </Box>
];

export default Contact;