import {Anchor, Box, Grid, Heading, List, Paragraph, ResponsiveContext, Text} from "grommet";
import {Cafeteria, Checkmark, Wifi} from "grommet-icons";
import ImageHeading from "./ImageHeading";
import {useContext} from "react";
import {ReactComponent as BedIcon} from './assets/icons/bed3.svg';
import {ReactComponent as AirConIcon} from './assets/icons/airCon.svg';

const iconColor = 'black'
const Rooms = (props) => {
    const size = useContext(ResponsiveContext);
    let columns = ['1/4', '1/4', '1/4', '1/4'];
    let width = '50%';
    if (size === 'small' || size === 'xxsmall' || size === 'xsmall') {
        columns = ['1/2', '1/2'];
        width = '100%';
    }

    const {color, room} = props;

    return (
        <Box align="center" margin={{
            bottom: "large"
        }}>
            <Box
                align="center"
                justify="center"
                height="medium"
                background={
                    {
                        image: `url(${room.src})`,
                        size: "cover",
                        color: "neutral-1",
                        dark: true
                    }
                }
                width="100%"
            >
                <Box pad={
                    {
                        left: "large"
                    }
                }
                     alignSelf="center"
                >
                    {/* <Heading
                        color={color}
                        className="title"
                    >{room.title}</Heading> */}
                </Box>

            </Box>
            <Box align="center" alignSelf="center" width={width} pad={"small"}>
                <Box>
                    <Heading alignSelf="center" level={'2'} className="title">{room.title}</Heading>
                    <Paragraph fill>
                        {room.description}
                    </Paragraph>
                </Box>
                <Box>
                    <Grid gap="small" columns={columns}>
                        {
                            icons.map((item, index) => {
                                return (
                                    <IconItem
                                        icon={item.icon}
                                        text={item.text}
                                        key={index}
                                    />
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Box>
            <Box width={width}>
                <Heading style={{textTransform: "uppercase"}} weight={"bold"} className="title" alignSelf="center"
                         level="2">
                    {room.title} AMENITIES
                </Heading>
                <List data={amenities} border={false} pad={
                    {
                        left: "none",
                        right: "none",
                        top: "small",
                        bottom: "small"
                    }
                }>
                    {
                        (item) => (
                            <Box direction="row" gap="medium" align="center">
                                <Checkmark size="small"/>
                                <Text weight="bold">{item}</Text>
                            </Box>
                        )
                    }
                </List>

            </Box>
        </Box>
    )
};

const amenities = [
    'Air Conditioning',
    `
    Fully fitted kitchen: 
    fridge, microwave, blender
    `,
    'Well equipped bathroom',
    'King size bed,2 single beds',
    'Baby cot upon request',
    'Television'
]

const RoomImageComp = ({room}) => {
    return (
        <ImageHeading
            alignment={'center'}
            level={'2'}
            title={room.title}
        />
    )
};
const IconItem = ({icon, text}) => {
    return (
        <Box align="center">
            <Anchor icon={icon}/>
            <Text color="grey" size="small" textAlign="center" style={{
                textTransform: "uppercase"
            }}>{text}</Text>
        </Box>
    )
}
const icons = [
    {
        icon: <BedIcon/>,
        text: 'King Sized Bed'
    },
    {
        icon: <Wifi color={iconColor}/>,
        text: 'Fast Wifi'
    },
    {
        icon: <AirConIcon/>,
        text: 'Air Conditioning'
    },
    {
        icon: <Cafeteria color={iconColor}/>,
        text: 'Fully-Equipped Kitchen'
    }
]

export default Rooms;