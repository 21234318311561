import {
    Image,
    Box,
    Text,
    Grid,
    ResponsiveContext,
    Heading
} from "grommet";
import { useContext } from "react";
import { useState } from "react";
import 'lightgallery/css/lightgallery.css';
import { useRef, useCallback, useEffect } from 'react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css'; import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import LightGallery from 'lightgallery/react';
import ImageCenteredComponent from "./ImageCenteredComponent";
const Gallery = ({ props }) => {
    const lightGallery = useRef(null);
    const size = useContext(ResponsiveContext);
    let heightWidth = 'small'
    //['1/2', '1/2'];
    let columns = null;
    const [currentSlide, setCurrentSlide] = useState(0);

    if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        columns = ['1/2', '1/2'];
        // heightWidth='100%'
    } else if (size === 'medium' || size === 'large') {
        columns = ['1/4', '1/4', '1/4', '1/4']
    } else {
        columns = ['1/6', '1/6', '1/6', '1/6', '1/6', '1/6']
    }
    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);
    const openSlider = (index) => {
        lightGallery.current.openGallery(index);
    }

    return (
        <Box align="center">
            <Box
                align="center"
                justify="center"
                background={
                    {
                        image: "url(./assets/images/pendo-villas-173.jpg)",
                        size: "cover",
                        color: "neutral-1",
                        opacity: "strong"
                    }
                }
                height="720px"
                width="100%"
            >
                <Heading className="title">Our Gallery</Heading>
                <hr/>
            </Box>
            <Heading className="title">Check Our Awesome Photos</Heading>
            <Box
                direction="row"
                align="center"
                justify="center"
            >
                <Grid
                    columns={columns}
                    pad="small"
                >
                    {
                        images.map((item, index) => {
                            return (
                                <Box
                                    height={heightWidth}
                                    width={heightWidth}
                                    pad="xsmall"
                                    key={index}
                                >
                                    <Image
                                        fit="cover"
                                        src={item.src}
                                        alt={item.alt}
                                        key={index}
                                        onClick={() => openSlider(index)}
                                    />
                                </Box>
                            )
                        })
                    }
                </Grid>
            </Box>
            <div id="lightGallery">
                <LightGallery
                    dynamic={true}
                    onInit={onInit}
                    dynamicEl={images}
                >

                </LightGallery>
            </div>
        </Box>
    )
};

const GalleryImageComp = () => {
    return (
        <Heading
            alignSelf="center"
            level="2"
            style={{
                position: "absolute",
                top: "50%"
            }}
        >GALLERY</Heading>
    )
}



const ScrollableGallery = (element) => {
    const lightGallery = useRef(null);

    const l = window.lightGallery(document.getElementById("lightGallery"));
    let columns = null;
    const [currentSlide, setCurrentSlide] = useState(0);
    const size = useContext(ResponsiveContext);
    if (size === 'small') {
        columns = 'full'
    } else if (size === 'medium') {
        columns = '1/4'
    } else {
        columns = '1/6'
    };
    const [items, setItems] = useState(images);
    const addItem = useCallback(() => {
        setItems([
            ...items,
            {
                id: '5',
                size: '1400-800',
                src: 'img-5.jpg',
                thumb: 'thumb-5.jpg',
            },
            {
                id: '6',
                size: '1400-800',
                src: 'img-6.jpg',
                thumb: 'thumb-6.jpg',
            },
        ]);
    }, []);
}
export default Gallery;

const images = [
    {
        src: "./assets/images/pendo-villas-173.jpg",
        alt: "1",
    },
    {
        src: "./assets/images/beach_1.jpg",
        alt: "2",
    },
    {
        src: "./assets/images/one_bedroom_1.jpg",
        alt: "One Bedroom One",
    },
    {
        src: "./assets/images/one_bedroom_2.jpg",
        alt: "One Bedroom Two",
    },
    {
        src: "./assets/images/one_bedroom_3.jpg",
        alt: "One Bedroom Three",
    },
    {
        src: "./assets/images/reception_1.jpg",
        alt: "Reception One",
    },
    {
        src: "./assets/images/reception_2.jpg",
        alt: "Reception Two",
    },
    {
        src: "./assets/images/reception_3.jpg",
        alt: "Reception Three",
    },
    // {
    //     src: "./assets/images/reception_4.jpg",
    //     alt: "Reception Four",
    // },
    {
        src: "./assets/images/rooftop_1.jpg",
        alt: "Rooftop One",
    },
    {
        src: "./assets/images/rooftop_2.jpg",
        alt: "Rooftop Two",
    },
    {
        src: "./assets/images/rooftop_3.jpg",
        alt: "Rooftop Three",
    },
    {
        src: "./assets/images/rooftop_4.jpg",
        alt: "Rooftop Four",
    },
    {
        src: "./assets/images/room-1.jpeg",
        alt: "Room Four",
    },
    {
        src: "./assets/images/room-2.jpeg",
        alt: "Room Four",
    },
    {
        src: "./assets/images/room-3.jpeg",
        alt: "Room Three",
    },
    {
        src: "./assets/images/room-5.jpeg",
        alt: "Room Five",
    },
    {
        src: "./assets/images/room-7.jpeg",
        alt: "Room Seven",
    },
    {
        src: "./assets/images/two_bedroom_1.jpg",
        alt: "Two Bedroom One",
    },
    {
        src: "./assets/images/swimming_pool_1.jpg",
        alt: "Swimming Pool One",
    },
    {
        src: "./assets/images/swimming_pool_2.jpg",
        alt: "Swimming Pool Two",
    },
    {
        src: "./assets/images/swimming_pool_3.jpg",
        alt: "Swimming Pool Three",
    },
    {
        src: "./assets/images/swimming_pool_4.jpg",
        alt: "Swimming Pool Four",
    },
    {
        src: "./assets/images/swimming_pool_5.jpg",
        alt: "Swimming Pool Five",
    },
];