import React, { useState, useContext } from "react";



import {
    Moon, Sun, Grommet as GrommetIcon, Menu as MenuIcon, Facebook, Instagram,
    FacebookOption,
    Twitter
} from "grommet-icons";
import logo from './assets/images/pendo-logo-white-1.png';
import { ReactComponent as WhatsappIcon } from './assets/icons/iconmonstr-whatsapp-1.svg';
import Base from './Foot';
import Head from "./Head";
import { Link, Outlet } from "react-router-dom";
import 'pure-react-carousel/dist/react-carousel.es.css';
import "react-alice-carousel/lib/alice-carousel.css";
import {
    Box,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Grid,
    grommet,
    Grommet,
    Header,
    Heading,
    Page,
    PageContent,
    PageHeader,
    Paragraph,
    ResponsiveContext,
    Text,
    Anchor, Menu, Image, Nav,
    Footer,
    Carousel,
    Main,
    Accordion,
    AccordionPanel
} from "grommet";
import { deepMerge } from "grommet/utils";
import Reviews from "./Reviews";
import Map from "./Map";
import Gallery from "./Gallery";
import FAQs from "./Faqs";
import './App.css';
import Rooms from "./Rooms";
import Contact from "./Contact";

const color = "#011640";
const Home = () => {
    const size = useContext(ResponsiveContext);
    let flexDirection = 'row';
    let columns = '1/2';
    if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        flexDirection = 'column';
        columns = '1'
    }
    return (
        <Box>
            <Main background="light-3">
                <Box
                    align="center"
                    justify="center"
                    background={
                        {
                            image: "url(./assets/images/home_page.jpg)",
                            size: "cover",
                            color: "black",
                            dark: true,
                            opacity: "strong"
                        }
                    }
                    height="720px"
                    width="100%"
                >
                    <Box
                        alignSelf="center"
                        align="center"
                        style={{
                            position: "absolute"
                        }}
                    >
                        <Heading
                            className="title"
                            margin="medium"
                            // color="white"
                        >Explore</Heading>
                        <Paragraph color={color}>
                            We are here to offer you a perfect holiday experience full of  memories to cherish.
                            Let's plan it  for you.
                        </Paragraph>
                        <Box direction="row" margin="medium">
                            <Link to="/about">
                              <Button primary color={color}  size="medium" label="Read More" />
                            </Link>
                         
                        </Box>
                    </Box>
                </Box>

                <Box pad={
                    {
                        top: "large",
                        bottom: "large",
                        left: "small"
                    }
                }>
                    <Grid columns={columns}
                        gap="xsmall">
                        <Box direction={flexDirection} pad={"small"}>
                            <Heading
                                level="1"
                                style={{
                                    textTransform: "uppercase"
                                }}>
                                <span className="title">
                                     Experience<br />
                                    Life at <br />
                                </span>
                                <span className="titleitalic">
                                    the South Coast of <br />
                                    Kenya
                                </span>

                            </Heading>
                        </Box>
                        <Box>
                            <Paragraph margin="none">
                                <strong style={{
                                    color: `${color}`
                                }}>Pendo Villas Diani</strong> is located within the famous Diani white sand beach of south coast Kenya.
                                We are less than one kilometre walking distance to the beachfront.
                                Our villas are designed to offer extreme luxury and comfort to our guests.
                            </Paragraph>
                            <Paragraph>
                                <br />
                                We are a 12 unit two-bedroom villas designed to provide extreme luxury and comfort to our guests. We strive to make your stay with us very memorable through our experienced staff.
                            </Paragraph>
                        </Box>
                    </Grid>

                </Box>
                <RoomsComp />
                <Amenity />
                <Box align="center"
                    pad="small"
                    margin={{
                        top: "20px",
                        bottom: "20px"
                    }}>
                    <Heading  textAlign={"center"} level="1" className="title" style={{
                        fontFamily: "PlayfairDisplay"
                    }}>What Our Guests Are Saying</Heading>
                    <Text textAlign={"center"} color="grey">We serve our guests to leave them with memorable experiences. Here is what they say</Text>
                </Box>
                <Reviews maxChars={100}
                    style={{
                        marginLeft: "50px",
                        marginRight: "50px",
                        width: "80%",
                        color: "purple",
                        display: "flex",
                        justifyContent: "center"
                    }} />
                {/* <Text margin={{
                        top:"30px"
                    }} textAlign="center"><strong>Google</strong> rating score: <strong>4.5</strong> of 5, based on <strong>96 reviews</strong></Text> */}
                <Map />
            </Main>
        </Box>
    )
};

const RoomsComp = () => {
    const size = useContext(ResponsiveContext);
    let flexDirection = 'row';
    let columns = '1/2';
    if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        flexDirection = 'column';
        columns = '1'
    }
    return (
        <Box pad="small" align="center">
            <Heading level="1" style={{
                borderBottom: "2px solid purple"
            }} className="title"
                     alignSelf="center"

            >Accommodation</Heading>
            <Grid columns={columns} gap="medium" align="center">
                {
                    rooms.map((item, index) => {
                        return (
                            <Box key={index} pad="none">
                                <Card style={{
                                    boxShadow: "none"
                                }} border={false}
                                    //  width="medium"
                                    key={item.title}
                                    pad="small"
                                    background="white"
                                >
                                    <CardBody direction="row">
                                        <Image
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%"
                                            }}
                                            height="auto"
                                            fit="contain"
                                            src={item.src}
                                            alt={item.alt}
                                        />
                                    </CardBody>
                                    <CardFooter align="center" alignContent="center" justify="center">
                                        <Box align="center" >
                                            <Heading
                                                level="3"
                                                textAlign="center"
                                                className="title"
                                                weight="bold"
                                                margin="none"
                                            >{item.title}</Heading>
                                            <Text color={color} size="medium">{item.capacity}</Text>
                                            <Link to={item.link}><Text color="grey" textAlign="center" weight="lighter">Discover</Text></Link>

                                        </Box>

                                    </CardFooter>
                                </Card>


                            </Box>
                        )
                    })
                }
            </Grid>

        </Box>
    )
};

const amenities = [
    {
        title: "Iconic Rooftops",
        description: `
        Enjoy your evening breeze and sunset from our rooftop.
        We have indoor activities you can enjoy while on the rooftop or balcony. Are you a Ludo-King? Or a lover of mentally stimulating chase? These await you at the rooftop.
        Our kids corner also has enough games for the your littles ones.
      `,
        image: "./assets/images/rooftop_3.jpg"
    },
    {
        title: "Swimming Pool",
        description: `
      Experience the joy of swimming in our pool that gives you a nice picturesque view. 
      It has both adult and kids sections.
      `,
        image: "./assets/images/swimming_pool_1.jpg"
    },
    {
        title: "Beach experiences",
        description: `
      We will help you experience beach life in its true sense. 
      Visits to Kisite Mpunguti marine park,glass boat rides,snorkelling and eating swahili traditional delicacies will walk you back home with life time memories.
      `,
        image: "./assets/images/glass_ride.jpg"
    }
];

const groupInThrees = (array, groupedCards) => {
    return array.reduce((result, item, index) => {
        const groupIndex = Math.floor(index / 3);

        if (!result[groupIndex]) {
            result[groupIndex] = [];
        }

        result[groupIndex].push(item);
        if ((index + 1) % 3 === 0) {
            groupedCards.push(<ParentCard key={groupIndex}>{result[groupIndex]}</ParentCard>);

        }
        return result;
    }, []);
};
const ParentCard = ({ children }) => {
    const size = useContext(ResponsiveContext);
    let flexDirection = 'row'
    let width = "medium"
    if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        flexDirection = 'column';
        width = "100%"
    }
    return (
        <Box
            gap="small"
            direction={flexDirection}
            pad="small"

        >
            {
                children.map(item => (
                    <AmenitiesItem
                        item={item}
                        key={item.title}
                        size={size}
                        width={width}
                    />
                ))
            }

        </Box>

    );
};

const AmenitiesItem = ({ item, width }) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <Box key={item.title}>
            <Card style={{
                boxShadow: "none"
            }} border={false}
                width={width}
                key={item.title}
                pad="medium"
                background="white"
            >
                <CardBody height="small" direction="row">
                    <Image
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%"
                        }}
                        height="auto"
                        fit="contain"
                        src={item.image}
                        a11yTitle={item.title}
                    />
                </CardBody>
                <Box pad={{ horizontal: 'medium' }} responsive={false}>
                    <Heading level="3" className="title" margin={{ vertical: 'medium' }}>
                        {item.title}
                    </Heading>
                    {
                        isReadMore ?
                            item.description.slice(0, 100).concat(' ...') :
                            <Paragraph>
                                {item.description}
                            </Paragraph>
                    }
                </Box>
                <CardFooter align="center" alignContent="center" justify="center">
                    <Box align="center" >
                        <Button onClick={toggleReadMore}>
                            <Box pad="small" direction="row" align="center" gap="small">
                                <Text>{isReadMore ? 'Read More' : 'Show Less'}</Text>
                            </Box>
                        </Button>
                    </Box>

                </CardFooter>
            </Card>
        </Box>
    )
}




const Amenity = () => {
    const jsxElements = [];
    const size = useContext(ResponsiveContext);
    let align = 'center'
    if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        align = 'stretch'
    }
    groupInThrees(amenities, jsxElements);
    return (
        jsxElements.length > 3 ? <Carousel>
            {jsxElements}
        </Carousel> :
            <Box align={align} pad="small">
                 <Heading alignSelf="center" className="titleline">Amenities</Heading>
                {jsxElements}
            </Box>
    )
};

const rooms = [
    {
        src: "./assets/images/one_bedroom_3.jpg",
        alt: "Room 1",
        title: "ONE BEDROOM",
        capacity: "1-2 People",
        link: "one-bedroom"
    },
    {
        src: "./assets/images/two_bedroom_1.jpg",
        alt: "Room 2",
        title: "TWO BEDROOM",
        capacity: "1-4 People",
        link: "two-bedroom"
    }
];

export default Home;