import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import router from "./Route";
import {BrowserRouter, Route, RouterProvider, Routes, ScrollRestoration} from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Rooms from "./Rooms";
import Gallery from "./Gallery";
import Contact from "./Contact";
import FAQs from "./Faqs";
import Experience from "./Experience";
import ScrollToTop from "./ScrollToTop";

const container = document.getElementById("root");
const root = createRoot(container);
const color ='#011640';
{/*<RouterProvider router={router}/>*/}
root.render(


        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index element={<Home color={color}/>}/>
                    <Route path="about" element={<About color={color}/>}/>
                    <Route path="one-bedroom" element={
                        <Rooms room={
                            {
                                src: "./assets/images/one_bedroom_2.jpg",
                                alt: "Room 1",
                                title: "One Bedroom",
                                description: `
              The one bedroom suite is fitted with a fully-equipped kitchen,
              master bedroom ensuite with a king sized bed and a rain shower/ bathtub,
               a guest cloakroom, a study working table, flat screen television with satellite & cable channels,
                complimentary wi-Fi connectivity and daily housekeeping services.
              `
                            }
                        }
                               color={color}
                        />
                    }/>
                    <Route path="two-bedroom" element={<Rooms room={
                        {
                            src: './assets/images/two_bedroom_1.jpg',
                            alt: 'Two Bedroom',
                            title: 'Two Bedroom',
                            description: `
            The one bedroom suite is fitted with a fully-equipped kitchen,
            master bedroom ensuite with a king sized bed and a rain shower/ bathtub,
             a guest cloakroom, a study working table, flat screen television with satellite & cable channels,
              complimentary wi-Fi connectivity and daily housekeeping services.
            `
                        }
                    }
                                                              color={color}
                    />}/>
                    <Route path="gallery" element={<Gallery color={color}/>}/>
                    <Route path="contact" element={ <Contact image={
                        {
                            src: "//v2.grommet.io/assets/IMG_4245.jpg",
                            alt: "Room 1",
                            title: "ONE BEDROOM"
                        }
                    }
                                                             color={color}
                    />}/>
                    <Route path="faqs" element={<FAQs color={color}/>}/>
                    <Route path="experience" element={<Experience color={color}/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
);