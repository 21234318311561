import React, {useState} from "react";
import Base from './Foot';
import Head from "./Head";
import {Outlet, ScrollRestoration} from "react-router-dom";
import 'pure-react-carousel/dist/react-carousel.es.css';
import "react-alice-carousel/lib/alice-carousel.css";
import {grommet, Grommet} from "grommet";
import {deepMerge} from "grommet/utils";
import './App.css';
import {WhatsAppWidget} from "react-whatsapp-widget";
import 'react-whatsapp-widget/dist/index.css';


const columns = '1/2';
const theme = deepMerge(grommet, {
    global: {
        colors: {
            brand: "#228BE6",
        },
        font: {
            family: "Roboto",
            size: "14px",
            height: "20px",
        },
    },
});


const App = () => {
    const [dark, setDark] = useState(false);
    console.warn = function () {
    };
    return (
        <Grommet full themeMode={dark ? "dark" : "light"}
        style={{
            overflowY: "scroll",
            overflowX:"scroll",
            height:"100%"
        }}
        >
            <Head/>
            <Outlet/>
            <Base/>
            <WhatsAppWidget
                phoneNumber="254799272727"
                companyName="Pendo Villas Diani"
                replyTimeText="Typically replies within an hour"
            />
        </Grommet>

    );
};


export default App;
