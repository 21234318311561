import {
    Box,
    Heading,
    Paragraph
    , ResponsiveContext,
    Text,
    Image, List
} from "grommet"
import {key} from "localforage";
import {useContext} from "react";

const width = "500px";
const height = "500px";
const Experience = ({props}) => {
    const size = useContext(ResponsiveContext);
    let flexDirection = 'row'
    let columns = '1';
    let itemTextDimension = width;
    if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        columns = ['1/2', '1/2'];
        flexDirection = 'column';
        itemTextDimension = 'auto'
    }
    return (
        <Box gap="large" margin="medium" background="light">
            <Box align="center">
                <Heading weight="bold" className="title">Things To Do</Heading>
                <Heading color={"grey"} level="4">Experience Diani</Heading>
            </Box>

            {
                experiences.map((item, index) => {
                    return (
                        <ExperienceItem
                            item={item}
                            index={index}
                            key={index}
                            size={size}
                            itemTextDimension={itemTextDimension}
                            boxProps={{
                                direction: {flexDirection},
                                align: "between",
                                justify: "center",
                                gap: "small"
                            }}
                        />
                    )
                })
            }

        </Box>
    )
};

const ExperienceItem = ({item, index, boxProps, size, itemTextDimension}) => {


    if ((index + 1) % 2 !== 0 || size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        return (
            <Box direction={boxProps.direction.flexDirection}
                 align={boxProps.align}
                 justify={boxProps.justify}
                 gap={boxProps.gap}
            >
                <ExperienceItemImage
                    item={item}
                />
                <ExperienceItemText
                    size={size}
                    item={item}
                    itemTextDimension={itemTextDimension}
                />
            </Box>
        )
    }
    return (
        <Box direction={boxProps.direction.flexDirection}
             align={boxProps.align}
             justify={boxProps.justify}
             gap={boxProps.gap}
        >
            <ExperienceItemText
                item={item}
                itemTextDimension={itemTextDimension}
            />
            <ExperienceItemImage
                item={item}
            />
        </Box>
    )
};

const ExperienceItemText = ({item, itemTextDimension}) => {
    return (
        <Box
            style={{
                width: `${itemTextDimension}`,
                height: `${itemTextDimension}`
            }}
        >
            <Heading className="title">{item.title}</Heading>
            <Paragraph>
                {item.description}
            </Paragraph>
            {
                item.attractions &&(
                     <Text weight="bold">Other attractions include: </Text>


                )
            }
            {
              <Text>
                  {
                      item.attractions && (
                          item.attractions.map((value,i) => {
                              return value.title.concat(CommaOrPeriod(item.attractions,i))
                          })
                      )
                  }
              </Text>

            }
        </Box>
    )
}

const CommaOrPeriod = (items,index) =>{
    if (items.length -1 === index+1){
        return ' and '
    }
    return  items.length === index+1 ? '.':','
}

const ExperienceItemImage = ({item}) => {
    return (
        <Image
            // style={{
            //     maxWidth: "100%",
            //     maxHeight: "100%"
            // }}
            height={height}
            width={width}
            src={item.image}
            alt={item.title}
        />
    )
}

const experiences = [
    {
        image: "./assets/images/beach_1.jpg",
        title: "Beach Walks",
        description: `
Less than a kilometre away, the world-famous Diani beach is within your reach.
 Take therapeutic beach walks as you listen to the sound of the waters as low tide quickly gives way to high tide in the evening.
        `
    },
    {
        image: "./assets/images/experience-diani.jpg",
        title: "Shopping",
        description: `
        Diani is laid back, quiet but do not let that trick you! From fresh groceries to the catch of the day, you will be spoilt for choice with the richness of the shopping experience at Diani. Some of Kenya's famous eateries are found here. 
        Remember to promote the local artisans with their colourful hats, deras and shangas. 
        `
    },
    {
        image: "./assets/images/experience-diani-tours.jpg",
        title: "Tours",
        description: `
        In Diani, you are spoilt for choice when it comes to tours. You can for instance do a Skydiving tour. If you are a deep sea type, scuba diving will go well with you. You can also do some snorkeling. Otherwise boat trips in a glass boat, will bring you up close and personal with the marine life in the Indian Ocean. If you want to venture out a bit further, and see a bit of the Big Five, why not try Shimba Hills in. 
        By the way, have you been to Chale Island? 
        `,
        attractions: [
            {
                title: 'Kisite Mpunguti Marine Park/Wasini Island',
                desc: `
                Add a touch of adventure during your stay at Pendo Villas Diani. Renowned for its
breathtaking coral gardens, diverse marine life, and crystal-clear waters, this marine
park offers an unparalleled experience for nature enthusiasts and adventure
seekers.
Grab your snorkeling gear or join a guided diving excursion to discover the
kaleidoscope of colors within the park&#39;s pristine coral reefs. Encounter an array of
marine species, including colorful fish, dolphins, sea turtles, and an occasional visit
from majestic whale sharks. The marine park also serves as a vital conservation
area, dedicated to preserving the rich marine biodiversity of the Indian Ocean.
                `
            },
            {
                title: 'Shimba Hills National Reserve',
                desc: `
               Just close by to Pendo Villas Diani is the great Shimba Hills, Offering a breathtaking landscape of
rolling hills, lush forests, and diverse wildlife, this reserve provides an immersive experience in the
heart of coastal Kenya. This is home to a variety of wildlife, including the endangered Sable
antelope, majestic elephants, leopards, giraffes, and an array of bird species. Traverse the winding
trails and witness the harmonious coexistence of flora and fauna within this unique ecosystem. 
                `
            },
            {
                title: 'Robbinson Island'
            },
            {
                title: 'Kongo River Beach'
            },
            {
                title: 'African Pool'
            },
            {
                title: 'Bora Bora Wildlife Park'
            },
            {
                title: 'Kaya Kinondo Forest'
            }
        ]
    },


]

export default Experience;