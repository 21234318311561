import { Box, AccordionPanel, Accordion, List, Text, Heading } from "grommet";
import { useState } from "react";


//const color ='#011640'
const FAQs = (props) => {
    const [activeIndex, setActiveIndex] = useState([]);
    const {color} = props;
    return (
        <Box
            align="start"
            justify="start"
            pad="medium"
        >
            <Heading className="title">Frequently Asked Questions</Heading>
            <Accordion
                animate={true}
                multiple
                activeIndex={activeIndex}
                onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}
            >
                {
                    faqs.map((item, index) => {
                        return (
                            <AccordionPanel
                                label={<Text>{item.question}</Text>} key={index}
                                header={renderPanelHeader(item.question, activeIndex.includes(index),color)}
                            >
                                <Box pad={
                                    {
                                        left: "medium",
                                        right: "medium",
                                        bottom: "medium"
                                    }
                                }
                                    width="large"
                                >
                                    <Text>{item.answer}</Text>
                                    {
                                        item.modes && (
                                            <List border={false} aria-label="order list" data={item.modes} />
                                        )
                                    }
                                    {
                                        item.disclaimer && (
                                            <Text weight="bold">{item.disclaimer}</Text>
                                        )
                                    }
                                </Box>

                            </AccordionPanel>
                        )
                    })
                }
            </Accordion>
        </Box>
    )
};
export default FAQs;

const renderPanelHeader = (title, active,color) => (
    <Box
        direction="row"
        align="start"
        pad="medium"
        gap="small"
        width="large"
        justify="between"
        background="light-1"
    >
        <strong>
            <Text>{title}</Text>
        </strong>
        <Text color={color}>{active ? '-' : '+'}</Text>
    </Box>
);
const faqs = [
    {
        question: "Where are you located?",
        answer: "We are located outside Ukunda airstrip, about 100 metres from the gate."
    },
    {
        question: "How far are you from the beach?",
        answer: "We are located 800 metres walking distance to the beach front along Ukunda airstrip road."
    },
    {
        question: "How can I reach Pendo Villas from SGR Mombasa?",
        answer: "One can take a taxi to Pendo Villas or take a shuttle which then drops at Ukunda stage and then take a taxi/tuk tuk to Pendo Villas Diani."
    },
    {
        question: "Do you allow very early morning check-in for guests arriving on night SGR?",
        answer: "Yes, we do if we do not have checkouts on the specific day of arrival."
    },
    {
        question: "What is your check-in time?",
        answer: "Check-in starts from 11 am."
    },
    {
        question: "What is your check-out time?",
        answer: "Check-out is by 10 am."
    },
    {
        question: "What are your modes of payment?",
        answer: "One can pay through:",
        modes: ['1. Till number', '2. Credit card', '3. Bank transfers'],
        disclaimer: "We do not accept cash payments."
    },
    {
        question: "Do you sell alcoholic drinks?",
        answer: "We do not sell alcohol but we have supermarkets & clubs nearby to meet your requirements."
    },
    {
        question: "Do you have a swimming pool?",
        answer: "Yes, we have a swimming pool with both adult & kids sections."
    },
    {
        question: "Do you have air conditioning in your rooms?",
        answer: "Yes, our bedrooms have both AC and fans."
    },
    {
        question: "Do you have WiFi?",
        answer: "Yes, we have WiFi within our villas."
    },
    {
        question: "Do you have parking?",
        answer: "Yes, we do provide free parking within the villas."
    },
    {
        question: "How can oen reach Pendo Villas from Ukunda Airstrip?",
        answer: "We are just a walking distance from the airstrip however one can take a taxi. We also provide free picking & dropping to Ukunda airstrip when alerted in advance."
    },
    {
        question: "Do you have baby cots?",
        answer: "Yes, we do provide baby cots free to our customers."
    }

]
