import {FacebookOption, Instagram, Language, Location, Mail, Organization, Phone, Twitter} from "grommet-icons";
import {Anchor, Box, Footer, ResponsiveContext, Text} from "grommet";
import {useContext} from "react";
import {Link} from "react-router-dom";

const color = "white";

const Media = ({ padding, iconPad }) => (
  <Box direction="row" gap="xxsmall" justify="center" pad={padding}>
    <Anchor
      a11yTitle="Find us on Instagram"
      href="https://www.instagram.com/pendovillasdiani/"
      icon={<Instagram color={color} />}
      pad={iconPad}
    />
    <Anchor
      a11yTitle="Chat with us on Facebook"
      href="https://www.facebook.com/pendovillasdiani"
      icon={<FacebookOption color={color} />}
      pad={iconPad}
    />
    <Anchor
      a11yTitle="Follow us on Twitter"
      href="https://twitter.com/"
      icon={<Twitter color={color} />}
      pad={iconPad}
    />
  </Box>
);
const Base = () => {
  const size = useContext(ResponsiveContext);
  let flexDirection = "row"
  let border = 'none'
  if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
    flexDirection = "column"
    border="1px solid white"
  }
  return (
    <Box
      responsive={true}
      background={"#011640"}
    >
      {/* <Box align="center" background="grey" pad="none">
        <Heading margin="small" level="3">Need help? Call our support team 24/7 at 0799 27 27 27</Heading>
      </Box> */}
      <Box
        direction={flexDirection}
        justify="between"
        color="white"
      >
        <Box gap="medium" pad="small" color="white" style={{
          borderBottom:`${border}`
        }}>

          <Text weight="bold" size="large">Pendo Villas Diani</Text>
          {
            link1.map((item, index) => {
              return (
                <Link key={index} to={item.link}>
                  <Text color="white">{item.description}</Text>
                </Link>
              )
            })
          }
        </Box>
        <Box gap="medium" pad="small" style={{
          borderBottom:`${border}`
        }}>

          <Text weight="bold" size={"xlarge"}>Experience</Text>

          <Link to="/experience">
            <Text color="white">Explore Diani</Text>
          </Link>
        </Box>
        <Box gap="medium" pad="small" style={{
          borderBottom:`${border}`
        }}>
          <Text size="xlarge" color={"white"} weight="bold">Support</Text>
          <Box direction="row" gap="small">
            <Phone color={color} />
            <Anchor
              label="+254 799 27 27 27"
              href="tel:254799272727"
              style={{
                color: "white"
              }}
            />
            /
            <Anchor
              label="+254 799 42 76 67"
              href="tel:254799427667"
              style={{
                color: "white"
              }}
            />
          </Box>
          <Anchor
            label="info@pendovillasdiani.com"
            href="mailto:info@pendovillasdiani.com"
            style={{
              color: "white"
            }}
            icon={<Mail color={color} />}
          />
          <Anchor
            label="www.pendovillasdiani.com"
            href="https://pendovillasdiani.com/"
            style={{
              color: "white"
            }}
            icon={<Language color={color} />}
          />
          <Box direction="row" gap="small">
            <Location color={color} />
            <Text>Next To Ukunda Air Strip, Off, Diani Beach</Text>
          </Box>
        </Box>
      </Box>
      <Box alignSelf="start">
        <Media
          padding={"small"}
          iconPad={"none"}
        />
      </Box>
      <Footer  background={"#011640"} pad="small">
        <Text alignSelf="center" color={color} size="small">
          &copy; {new Date().getFullYear()} All rights reserved
        </Text>
      </Footer>
    </Box>

  )
};

export default Base;

const link1 = [
  {
    link: "/about",
    description: "About"
  },
  {
    link: "/gallery",
    description: "Gallery"
  },
  {
    link: "/one-bedroom",
    description: "One Bedroom"
  },
  {
    link: "/two-bedroom",
    description: "Two Bedroom"
  },
  {
    link: "/faqs",
    description: "FAQs"
  },
  {
    link: "/contact",
    description: "Contact Us"
  },
]