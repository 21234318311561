
import {Grommet,Box,Paragraph,Text} from "grommet";
import {Link} from "react-router-dom";
const NotFound = () =>{
    return (
        <Box>
            <Text size="xlarge">Invalid Request</Text>
            <Link to={"home"}>Home</Link>
        </Box>
    )
};

export default NotFound;