import { Box } from "grommet";

const Map = () => {
    return (
      <Box pad="large" background="light-1" justify="center" align="center">
        <iframe
          width="80%"
          height="450"
          style={{border:"0"}} 
          allowFullScreen
          loading="lazy"
        //   allowfullscreen
        referrerPolicy="no-referrer-when-downgrade"
          src="https://www.google.com/maps/embed?origin=mfe&pb=!1m4!2m1!1sPendo+Villas,+Diani!5e0!6i10">
        </iframe>
      </Box>
    )
  };

  export default Map;