
import {
    Box, StarRating, Heading, Paragraph,
    Text,
    Grid,
    Collapsible,
    Button,
    Carousel,
    Accordion,
    AccordionPanel,
    ResponsiveContext
} from "grommet";
import { useState, useEffect, useContext } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Rating } from 'react-simple-star-rating';
import * as dayjs from 'dayjs'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import React from 'react';
const date = new Date().toISOString();
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};


const Reviews = ({ maxChars }) => {
    const [parentState, setParentState] = useState(0);
    const size = useContext(ResponsiveContext)
    const readMore = reviews[0].description.length > maxChars;
    const [isReadMore, setIsReadMore] = useState(readMore);
    let width="80%"
    if (size === 'small' || size === 'xsmall' || size === 'xxsmall') {
        width="100%"
      }
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            autoHeight={true}
            navigation
            autoplay={true}
            style={{
                 margin: "50px auto 50px auto",
                width: "80%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
                border:"1px solid #abb8c3",
                padding:"30px 10px 30px 10px"
            }}

        >
            {
                reviews.map((item, index) => (
                    <SwiperSlide
                        key={index}
                        index={index}
                    >
                        <Box
                         pad={{
                            top:"small",
                            left:"xlarge",
                            right:"xlarge",
                            bottom:"small"
                        }} 
                        justify="center" 
                        align="center"
                        gap="small">
                            <Heading margin="none" className="title-3" level="3">{item.name}</Heading>
                            <Text color="grey" size="xsmall">{item.date}</Text>
                            <Rating
                                initialValue={item.rating}
                                disableFillHover={true}
                                readonly
                                size={18}
                            /* Available Props */
                            />
                            <Paragraph>{item.description}</Paragraph>

                        </Box>
                    </SwiperSlide>

                ))
            }
        </Swiper>

    )

};

const Rev = ({ item, maxChars, doReadMore, index }) => {
    const readMore = item.description.length > maxChars;
    const [isReadMore, setIsReadMore] = useState(readMore);
    const [open, setOpen] = React.useState(false);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };


    return (
        <SwiperSlide>
            <Heading level="1">{item.name}</Heading>
            {/* <Text>{item.date}</Text>
            <StarRating name="rating" /> */}
        </SwiperSlide>


    )
}

export const reviews = [
    {
        avatar: "",
        name: "Masai Morani",
        description: `
      It is a beautiful place to be with family
  Bishop safaris always gives the best vacation in Diani with Pendo Villas
      `,
        date: dayjs("2022-03-21").format("DD-MM-YYYY"),
        rating: 5
    },
    {
        avatar: "",
        name: "Kawira Njeru ",
        description: `
        The welcome hand towels are very nice, with aromatherapy oils. Plus the cold welcome drink for all guests upon arrival.
        Location is great
        Enough sockets in every room
        Spacious rooms
        Reliable and fast WiFi services
        Beautiful set up at the rooftops for mealtimes
        Beds are comfortable, clean linen and helpful staff. The writer was very kind and helpful.
        The kitchen is well equipped for one to make their own meals: blender for making smoothies,electric water kettle for heating water,microwave, fridge , water dispenser, rack for vegetables.
        There is a safe to keep one's valuables in the room.
        Room service team cleans the houses adequately.
      `,
        date: dayjs("2022-02-12").format("DD-MM-YYYY"),
        rating: 4
    },
    {
        avatar: "",
        name: "Wendy kimonye",
        description: `
      It was a perfect stay
      The hosts were kind, professional and ready to assist anytime. Diana ,Ben and the housekeeping lady were amazing.
      It was very clean from rooms , beddings and environment.
      The pool is also very clean
      Kitchen was stocked well and AC worked well in the rooms.
      We were a group of 8 and really enjoyed the stay. We will be back
      The cost is very fare
      The villas are a walking distance to ukunda airstrip.
      `,
        date: dayjs("2022-02-01").format("DD-MM-YYYY"),
        rating: 5
    },
    {
        avatar: "",
        name: "Carolyn Nganga ",
        description: `
        Great place. The staff were very helpful and the rooms very clean throughout.
         I stayed with my family members from 29th Dec '21 to 2nd Jan '22 and had an awesome time. Keep it up.
        `,
        date: dayjs("2022-01-02").format("DD-MM-YYYY"),
        rating: 5
    },
    {
        avatar: "",
        name: "Top 15 Best",
        description: `
        My stay was excellent ❤had the best room service and good reception by every worker.
         The female and male receptionists were welcoming sure to come next time.
        `,
        date: dayjs("2021-12-28").format("DD-MM-YYYY"),
        rating: 5
    },
    {
        avatar: "",
        name: "GRACE WANJIKU",
        description: `
        Very friendly and accommodative staff, well kept lawns, superb rooms, veeery good and assorted cutlery.
         Well maintained swimming pool...You won't regret your stay at pendo villa diani beach....I'll be back soonest.
        `,
        date: dayjs("2021-12-09").format("DD-MM-YYYY"),
        rating: 5
    },
    {
        avatar: "",
        name: "Susan Karingi",
        description: `
        It is a home away from home. The apartments are clean and well stocked. You can cook or even hire a chef. Their swimming pool is always clean and well maintained. The staff is friendly and ever ready and willing to help.
         Its a good pocket friendly place to bring your family.
        `,
        date: dayjs("2021-10-21").format("DD-MM-YYYY"),
        rating: 5
    }
];
export default Reviews;